import React from 'react';
import * as types from '../../../redux/futures/store/types';
import storeActions from '../../../redux/futures/store/actions';
import _, { max } from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import baseHelper from '../../../helpers/BaseHelper';
import appConst from '../../../config/const';


class ActiveLadiChat extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            store: {
                _id: '',
                store_id: '',
                name: '',
                ladi_uid: '',
                pkg_name: '',
                pkg_exp: '',
                pkg_buy_exp: '',
                pkg_buy_price: '0',
                created_at: '',
                web_id: '',
                blog_id: '',
                resource_limits: false
            },
            userinfo: {
                point: '',
                email: '',
                fullname: '',
                lastname: '',
                phone: ''
            },
            inputTag: '',
            listTags: [],
            type: props.typeUpgrade,
            appType: props.appType,
            note: '',
            affilateCode: '',
            appCode: 'LP',
            packages: [],
            selectedPackage: '',
            month: "3",
            addition_month: "0",
            discount: "0",
            max_domain: "0",
            max_link: "0",
            max_funnel: "0",
            max_funnelx: "0",
            max_step_action: "0",
            max_traffic: "0",
            point: "0",
            totalPrice: 0,
            backMoney: 0,
            method: appConst.PAYMENT_STATUS_OPTIONS.PAID.value,
            returned: appConst.PAYMENT_RETURN_OPTIONS.RETURNED.value,
            is_test: false,
            loading: false,
            loaderPackages: true,
            classNormal: "ladiui button primary",
            classLoading: "ladiui loader button primary",
            classApply: "ladiui button primary"
        }
        this.initialState = _.cloneDeep(this.state);
        this.inputsRef = new Set();

        this.ref = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.appCode !== nextProps.appCode) {
            this.setState({ appCode: nextProps.appCode })
        }
        if (this.state.appType !== nextProps.appType) {
            this.setState({ appType: nextProps.appType });
        }
        if (this.state.type !== nextProps.typeUpgrade) {
            this.setState({ type: nextProps.typeUpgrade })
        }

        if (this.props.currentStore !== nextProps.currentStore) {
            if (!nextProps.currentStore) {
                this.resetForm();
            } else {
                if (nextProps.currentStore.resource_limits) {
                    nextProps.currentStore.resource_limits = JSON.parse(nextProps.currentStore.resource_limits);
                }
                this.setState({
                    store: _.pick(nextProps.currentStore, _.keys(this.state.store)),
                    appCode: nextProps.appCode,
                    loaderPackages: true,
                    packages: []
                })
            }
        }

        if (nextProps.action === types.GET_USERINFO) {
            // this.setState({loaderPackages: false});
            // console.log(nextProps.userinfo);
            if (nextProps.status && nextProps.userinfo) {
                this.setState({
                    userinfo: nextProps.userinfo
                });
            } else {
                // window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action === types.LIST_PACKAGE) {

            this.setState({ loaderPackages: false });
            if (nextProps.status && nextProps.packages.length > 0) {
                let { store, appCode, max_domain, max_link, max_funnel, max_funnelx, max_step_action
                    , max_traffic, appType } = this.state;
                const expDate = new Date(store.pkg_exp);
                const isExp = baseHelper.diffDateDay(expDate, new Date()) > 0;

                let selectedPackage = null;
                let list = [];
                // window.LadiUI.toastr('success', '', nextProps.message);
                for (let i = 0; i < nextProps.packages.length; i++) {
                    // if (nextProps.packages[i].price == 0) {
                    if (!nextProps.packages[i].pkg_name && nextProps.packages[i].package_name) {
                        nextProps.packages[i].pkg_name = nextProps.packages[i].package_name;
                    }
                    list.push(nextProps.packages[i]);
                    // }
                }
                // if (price) {
                for (let i = 0; i < list.length; i++) {
                    if (store.pkg_name == list[i].name) {
                        selectedPackage = list[i];
                        break;
                    }
                }
                // } else {
                // list = nextProps.packages;
                // }

                list = list.sort(function (a, b) {
                    if (a.price > b.price) return 1;
                    if (a.price < b.price) return -1;
                    return 0;
                });
                if (!selectedPackage) {
                    selectedPackage = list[0];
                }
                // console.log(list, ' selectedPackage = ', selectedPackage, '-store = ', store);
                if (appCode == appConst.STORE_CONFIG.APP_CODE.LLINK.value) {
                    if (selectedPackage.resource_limits) {
                        max_link = selectedPackage.resource_limits.MAX_LINK + "";
                        max_domain = selectedPackage.resource_limits.MAX_DOMAIN + "";
                    }
                }
                if (appCode == appConst.STORE_CONFIG.APP_CODE.LP_APP.value && appType == appConst.STORE_CONFIG.LP_APP_CODE.FunnelX) {
                    console.log()
                    if (selectedPackage.pkg_name == store.pkg_name && store.resource_limits) {
                        max_domain = store.resource_limits.MAX_DOMAIN + "";
                        max_funnel = store.resource_limits.MAX_FUNNEL + "";
                        max_funnelx = store.resource_limits.MAX_FUNNELX + "";
                        max_step_action = store.resource_limits.MAX_STEP_ACTION + "";
                        max_traffic = store.resource_limits.MAX_TRAFFIC + "";
                    } else if (selectedPackage.resource_limits) {
                        max_domain = selectedPackage.resource_limits.MAX_DOMAIN + "";
                        max_funnel = selectedPackage.resource_limits.MAX_FUNNEL + "";
                        max_funnelx = selectedPackage.resource_limits.MAX_FUNNELX + "";
                        max_step_action = selectedPackage.resource_limits.MAX_STEP_ACTION + "";
                        max_traffic = selectedPackage.resource_limits.MAX_TRAFFIC + "";
                    }
                }
                this.setState({
                    packages: list,
                    max_link,
                    max_domain,
                    max_funnel,
                    max_funnelx,
                    max_step_action,
                    max_traffic,
                    selectedPackage: selectedPackage,
                    loaderPackages: false
                }, () => {
                    this.calculateMoney();
                });
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }

            // this.list();
        }
        if (nextProps.action == types.UPGRADE_STORE) {
            this.setState({
                loading: false,
                classApply: this.state.classNormal,
                listTags: []
            });
            if (nextProps.status) {
                const _seft = this;
                // window.LadiUI.toastr('success', '', nextProps.message);
                setTimeout(function () {
                    window.LadiUI.closeModal(_seft.ref.current);
                }, 1000);

            } else {
                // window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

    }

    pickPackages(name) {
        let { packages, max_domain, max_link, max_funnel, max_funnelx, max_step_action
            , max_traffic, appCode, appType } = this.state;

        for (let i = 0; i < packages.length; i++) {
            if (packages[i].name === name) {
                if (packages[i].resource_limits && appCode == appConst.STORE_CONFIG.APP_CODE.LLINK.value) {
                    max_link = packages[i].resource_limits.MAX_LINK + "";
                    max_domain = packages[i].resource_limits.MAX_DOMAIN + "";
                }
                if (packages[i].resource_limits && appCode == appConst.STORE_CONFIG.APP_CODE.LP_APP.value && appType == appConst.STORE_CONFIG.LP_APP_CODE.FunnelX) {
                    max_domain = packages[i].resource_limits.MAX_DOMAIN + "";
                    max_funnel = packages[i].resource_limits.MAX_FUNNEL + "";
                    max_funnelx = packages[i].resource_limits.MAX_FUNNELX + "";
                    max_step_action = packages[i].resource_limits.MAX_STEP_ACTION + "";
                    max_traffic = packages[i].resource_limits.MAX_TRAFFIC + "";
                }
                this.setState({
                    selectedPackage: packages[i],
                    max_domain,
                    max_link,
                    max_funnel,
                    max_funnelx,
                    max_step_action,
                    max_traffic
                }, () => {
                    this.calculateMoney();
                });
                break;
            }
        }

    }

    resetForm = (event) => {
        // console.log('===resetForm===');
        if (event && event.target && !event.target.hasAttribute("data-dismiss")) {
            return;
        }


        this.setState({
            ..._.cloneDeep(this.initialState)
        })
    }

    calculateMoney = () => {

        const { month, point, discount, selectedPackage, store, returned } = this.state;

        let backMoney = 0;

        if (store.pkg_buy_exp && store.pkg_buy_exp != '') {
            const diffDate = baseHelper.diffDateDay(new Date(), new Date(store.pkg_buy_exp));

            backMoney = store.pkg_buy_price ? parseInt(store.pkg_buy_price) * diffDate : 0;
            if (backMoney < 0) backMoney = 0;
        }
        if (selectedPackage.name == store.pkg_name || (returned == 'NO_RETURN')) {
            backMoney = 0;
        }
        let totalPrice = selectedPackage.price * month - point * 1000 - discount - backMoney;
        if (totalPrice < 0) {
            totalPrice = 0
        }

        totalPrice = Math.round(totalPrice / 1000) * 1000;

        this.setState({
            totalPrice,
            backMoney
        });
    }

    onChangePoint = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            point: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeDiscount = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            discount: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeMaxResource = (event) => {
        const { min } = event.target;

        if (min) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onChangeAdditionMonth = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) < min) {
                return false;
            }
        }

        this.setState({
            addition_month: event.target.value
        }, () => {
            this.calculateMoney();
        });
    }

    onChangeInput = (event) => {
        const { min, max } = event.target;

        if (min && max) {
            if (parseInt(event.target.value) > max || parseInt(event.target.value) < min) {
                return false;
            }

        }

        this.setState({
            month: event.target.value

        }, () => {
            this.calculateMoney();
        });
    }

    onChangeAffilateCode = (event) => {

        this.setState({
            affilateCode: event.target.value
        });
    }
    submit = () => {
        // Validate  
        let errors = [];
        const inputsRef = _.compact(Array.from(this.inputsRef));
        _.map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        _.compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastr('error', '', errorMessage);
            return;
        }

        const { listTags, store, month, selectedPackage, method, affilateCode, addition_month, discount, point, note, returned, appCode, type, is_test,
            appType,
            max_domain,
            max_link,
            max_funnel,
            max_funnelx,
            max_step_action,
            max_traffic } = this.state;
        // console.log('store ', store, '-type:', type);
        let storeUpdate = {
            store_id: store.store_id,
            month,
            package_name: selectedPackage.package_name,
            web_id: store.web_id || false,
            blog_id: store.blog_id || false,
        };

        if (appCode == appConst.STORE_CONFIG.APP_CODE.LLINK.value) {
            storeUpdate.resource_limits = {
                MAX_DOMAIN: max_domain,
                MAX_LINK: max_link
            }
        }
        if (appCode == appConst.STORE_CONFIG.APP_CODE.LP_APP.value && appType == appConst.STORE_CONFIG.LP_APP_CODE.FunnelX) {
            storeUpdate.resource_limits = {
                MAX_DOMAIN: max_domain,
                MAX_FUNNELX: max_funnelx,
                MAX_FUNNEL: max_funnel,
                MAX_STEP_ACTION: max_step_action,
                MAX_TRAFFIC: max_traffic
            }
            storeUpdate.app_id = store._id;
        }
        const data = {
            ladi_uid: store.ladi_uid,
            store:
                [
                    storeUpdate
                ],
            app_code: appCode,
            app_type: appType,
            status: method,
            returned,
            discount,
            addition_month,
            pkg_exp: store.pkg_exp,
            point,
            note,
            type,
            is_test,
            tags: listTags
        };
        if (affilateCode != '') {
            data.affilate_code = affilateCode;
        }
        // console.log(data); return;
        this.props.upgrade(data);
        this.state.loading = true;
        this.state.classApply = this.state.classLoading;
    }

    onChangeMethod = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    onChangeReturned = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            this.calculateMoney();
        });
    }

    onChangePackage = (event) => {

        this.pickPackages(event.target.value);
        // this.setState({
        //     selectedPackage: {
        //         package_name: event.target.value
        //     }
        // }, () => {
        //     this.calculateMoney();
        // });
    }

    onChangeTextArea = (event) => {

        this.setState({
            note: event.target.value

        });
    }

    handleClickOutside = () => {
        this.props.onCancel();
    }

    onChangeIsTest = (event) => {
        this.setState({
            is_test: event.target.value,
        });
    }
    onChangeTag = (event) => {
        this.setState({
            inputTag: event.target.value,
        });
    };
    onKeyDownInput = (event) => {
        if (event.keyCode == 13 || event.charCode == 13) {
            this.addTag();
        }
    };

    addTag = () => {
        let { inputTag, listTags } = this.state;
        const { t } = this.props;

        if (listTags.indexOf(inputTag) < 0) {
            listTags.push(inputTag);
            this.setState({
                listTags,
                inputTag: "",
            });
        } else {
            window.LadiUI.toast("danger", "", t('WIDGET.ERROR_DOMAIN_EXIST'));
        }
    };

    removeTag = (tag) => {
        const { listTags } = this.state;
        const index = listTags.indexOf(tag);
        if (index >= 0) {
            listTags.splice(index, 1);
            this.setState({ listTags });
        }
    };

    render() {
        this.inputsRef = new Set();
        const { store, packages, selectedPackage, month, loaderPackages, affilateCode, point, totalPrice, userinfo, addition_month, discount, backMoney, appCode,
            allTag, inputTag, listTags, type, is_test, max_domain, max_link,
            max_funnel, max_funnelx, max_step_action, max_traffic, appType } = this.state;
        let title = "Nâng cấp hoặc gia hạn store: " + userinfo.email;
        if (userinfo.is_partner_aff) {
            title += " - Mã Partner : " + userinfo.affilate_code;
        }
        let appName = appConst.STORE_CONFIG.APP_CODE[appCode].name;
        // console.log('appType = ', appType)
        return (
            <div ref={this.ref} id="modal-upgrade-store" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-upgrade-store" style={{ zIndex: 21 }} >
                <div className={`ladiui-modal-content ${loaderPackages ? 'loader' : ''}`} >
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-upgrade-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-upgrade-store" onClick={this.props.onCancel} /></button>
                        <h3>{title}<span className="app-name">{appName}</span>{appType && <span className="app-name">{appType}</span>}</h3>

                    </div>
                    <div className={"ladiui-modal-body"}>

                        <div className="ladiui-row" >
                            <div className="col-50" style={{ height: "40px" }} >
                                <strong>Tên store: </strong>
                                {
                                    store.name
                                }
                            </div>


                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Ladi UID: </strong>
                                {
                                    store.ladi_uid
                                }
                            </div>
                        </div>
                        <div className="ladiui-row"  >
                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Tên gói: </strong>{store.pkg_name}
                            </div>
                            <div className="col-50" style={{ height: "40px" }}>
                                <strong>Ngày hết hạn: </strong>{baseHelper.formatStrToDate(store.pkg_exp)}
                                {' (' + baseHelper.numberWithCommas(backMoney) + ')'}
                            </div>
                        </div>
                        <form>

                            <div>
                                <div className="ladiui form-group">
                                    <h3>Lựa chọn Loại giao dịch</h3>
                                </div>
                                <div className="ladiui-row">
                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            id="is-testing"
                                            name="is_test"
                                            value={appConst.IS_TESTING.YES.value}
                                            onChange={this.onChangeIsTest}
                                        />
                                        <label htmlFor="is-testing">{appConst.IS_TESTING.YES.name}</label>
                                    </div>


                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            name="is_test"
                                            id="is-not-testing"
                                            value={appConst.IS_TESTING.NO.value}
                                            onChange={this.onChangeIsTest}
                                            defaultChecked
                                        />
                                        <label htmlFor="is-not-testing">{appConst.IS_TESTING.NO.name}</label>
                                    </div>

                                </div>


                                <div className="ladiui form-group">

                                    <h3>Lựa chọn trừ ngày còn hạn</h3>
                                </div>

                                <div className="ladiui-row">


                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            id="payment-return"
                                            name="returned"
                                            value={appConst.PAYMENT_RETURN_OPTIONS.RETURNED.value}
                                            onChange={this.onChangeReturned}

                                            defaultChecked
                                        />
                                        <label htmlFor="payment-return">{appConst.PAYMENT_RETURN_OPTIONS.RETURNED.name}</label>
                                    </div>


                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            name="returned"
                                            id="payment-no-return"
                                            value={appConst.PAYMENT_RETURN_OPTIONS.NO_RETURNED.value}
                                            onChange={this.onChangeReturned}
                                        />
                                        <label htmlFor="payment-no-return">{appConst.PAYMENT_RETURN_OPTIONS.NO_RETURNED.name}</label>
                                    </div>

                                </div>
                                <div className="ladiui form-group">

                                    <h3>Lựa chọn hình thức thanh toán</h3>
                                </div>

                                <div className="ladiui-row">


                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            id="payment-paid"
                                            name="method"
                                            value={appConst.PAYMENT_STATUS_OPTIONS.PAID.value}
                                            onChange={this.onChangeMethod}

                                            defaultChecked
                                        // checked={this.state.method === appConst.PAYMENT_STATUS_OPTIONS.PAID.value}
                                        />
                                        <label htmlFor="payment-paid">Đã nhận tiền</label>
                                    </div>


                                    <div className="col-50" style={{ height: "40px" }}>
                                        <input type="radio"
                                            name="method"
                                            id="payment-paid-debit"
                                            value={appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value}
                                            onChange={this.onChangeMethod}

                                        // checked={this.state.method === appConst.PAYMENT_STATUS_OPTIONS.PAID_DEBIT.value}
                                        />
                                        <label htmlFor="payment-paid-debit">Chuyển khoản sau</label>
                                    </div>

                                </div>
                                <div className="ladiui form-group condition-form">

                                    <h3>Lựa chọn gói dịch vụ</h3>
                                </div>
                                <div className="ladiui-row">
                                    {
                                        _.map(packages, (packageObj, indx) => {
                                            // if (packageObj.price != undefined && packageObj.price == 0) {
                                            //     return <div key={"package-" + packageObj.name}></div>
                                            // }
                                            let displayName = packageObj.name;
                                            if (packageObj.package_name == 'PREMIUM_5' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_3';
                                            }
                                            if (packageObj.package_name == 'PREMIUM_10' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_10';
                                            }
                                            if (packageObj.package_name == 'PREMIUM_20' && appCode == 'LP') {
                                                displayName = 'BUSSINESS_20';
                                            }
                                            return <div className="ladiui form-group" style={{ height: "40px" }} key={"package-" + packageObj.name + "-" + indx}>

                                                <input type="radio"

                                                    id={"package-" + packageObj.package_name}
                                                    name="selectedPackage"
                                                    value={packageObj.package_name}
                                                    onChange={this.onChangePackage}
                                                    // defaultChecked
                                                    checked={selectedPackage.package_name == packageObj.package_name}
                                                />

                                                <label htmlFor={"package-" + packageObj.package_name}>{displayName}</label>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="ladiui form-group" >
                                    <h3>Point hiện có: {baseHelper.numberWithCommas(userinfo.point)}</h3>
                                </div>
                                <div className="ladiui-row" >
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Sử dụng point</label>
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="point"
                                                validationName="Point"
                                                placeholder="Point"
                                                value={point}
                                                type='number'
                                                onChange={this.onChangePoint}
                                                validations={{ isRequired: true, min: 0 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <label className="ladiui-label">Tổng tiền =
                                                Tháng * giá - point - tiền còn lại - giảm giá
                                            </label>
                                            <input name="totalPrice" className="ladiui input" disabled="disable" value={baseHelper.numberWithCommas(totalPrice)} />
                                        </div>

                                    </div>
                                </div>

                                <div className="ladiui-row" >

                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Số tháng</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="month"
                                                validationName="Month"
                                                placeholder="Số tháng gia hạn"
                                                value={month}
                                                type='number'
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true, min: 1, max: 60 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">

                                        <div className="ladiui form-group">
                                            <h3>Affiliate Code</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            {
                                                userinfo.affilate_code ?
                                                    <input name="affilateCode" className="ladiui input" disabled="disable" value={userinfo.affilate_code} /> :
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="affilateCode"
                                                        validationName="Affiliate Code"
                                                        placeholder="Mã affiliate"
                                                        value={affilateCode}
                                                        onChange={this.onChangeAffilateCode}
                                                    />
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="ladiui-row" >

                                    <div className="col-50">
                                        <div className="ladiui form-group">
                                            <h3>Số tháng cộng thêm</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="addition_month"
                                                validationName="Addition Month"
                                                placeholder="Số tháng cộng thêm"
                                                value={addition_month}
                                                type='number'
                                                onChange={this.onChangeAdditionMonth}
                                                validations={{ isRequired: true, min: 1 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-50">

                                        <div className="ladiui form-group">
                                            <h3>Giảm giá: {baseHelper.numberWithCommas(discount)}</h3>
                                        </div>

                                        <div className="ladiui form-group">
                                            <Input
                                                ref={(ref) => this.inputsRef.add(ref)}
                                                name="discount"
                                                validationName="Discount value"
                                                placeholder="Giảm giá"
                                                type='number'
                                                value={discount}
                                                onChange={this.onChangeDiscount}
                                            />
                                        </div>
                                    </div>

                                </div>
                                {appCode == appConst.STORE_CONFIG.APP_CODE.LLINK.value &&
                                    <div className='store-resource-limit'>
                                        <div className="ladiui-row" >

                                            <div className="col-50">
                                                <div className="ladiui form-group">
                                                    <h3>Domain Limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_domain"
                                                        validationName="Domain Limit"
                                                        placeholder="Domain Limit"
                                                        value={max_domain}
                                                        type='number'
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">

                                                <div className="ladiui form-group">
                                                    <h3>Link limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_link"
                                                        validationName="Link limit"
                                                        placeholder="Link limit"
                                                        type='number'
                                                        value={max_link}
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                {appCode == appConst.STORE_CONFIG.APP_CODE.LP_APP.value &&
                                    <div className='store-resource-limit'>
                                        <div className="ladiui-row" >

                                            <div className="col-50">
                                                <div className="ladiui form-group">
                                                    <h3>Domain Limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_domain"
                                                        validationName="Domain Limit"
                                                        placeholder="Domain Limit"
                                                        value={max_domain}
                                                        type='number'
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-50">

                                                <div className="ladiui form-group">
                                                    <h3>Funnel limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_funnel"
                                                        validationName="Funnel limit"
                                                        placeholder="Funnel limit"
                                                        type='number'
                                                        value={max_funnel}
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="ladiui-row" >

                                            <div className="col-50">
                                                <div className="ladiui form-group">
                                                    <h3>Nhóm FunnelX Limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_funnelx"
                                                        validationName="Nhóm FunnelX Limit"
                                                        placeholder="Nhóm FunnelX Limit"
                                                        value={max_funnelx}
                                                        type='number'
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-50">

                                                <div className="ladiui form-group">
                                                    <h3>Traffic limit</h3>
                                                </div>

                                                <div className="ladiui form-group">
                                                    <Input
                                                        ref={(ref) => this.inputsRef.add(ref)}
                                                        name="max_traffic"
                                                        validationName="Traffic limit"
                                                        placeholder="Traffic limit"
                                                        type='number'
                                                        value={max_traffic}
                                                        onChange={this.onChangeMaxResource}
                                                        validations={{ isRequired: true, min: 0 }}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="ladiui-row" >
                                            <div className="ladiui form-group">
                                                <h3>Step action Limit</h3>
                                            </div>

                                            <div className="ladiui form-group">
                                                <Input
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    name="max_step_action"
                                                    validationName="Step action Limit"
                                                    placeholder="Step action Limit"
                                                    value={max_step_action}
                                                    type='number'
                                                    onChange={this.onChangeMaxResource}
                                                    validations={{ isRequired: true, min: 0 }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                }
                                <div className="ladiui-row">
                                    <h3>Nội dung chuyển khoản: </h3>
                                </div>
                                <div className="ladiui-form-group">
                                    <textarea name="note" className="ladiui textarea ladi-payment-info"
                                        value={this.state.note} onChange={this.onChangeTextArea} />
                                </div>
                                <div className="ladiui-row" style={{ marginTop: "15px" }}>
                                    <h3>Nhập tag nếu có: </h3>
                                </div>
                                <div className="ladiui-row" style={{ marginTop: "15px" }}>
                                    <Input
                                        inputSize="input-medium"
                                        tabIndex={1}
                                        id="white-list-domain"
                                        name="white-list-domain"
                                        validationName=""
                                        placeholder="Tag"
                                        // hasBlurValidate={true}
                                        value={inputTag}
                                        onChange={this.onChangeTag}
                                        onKeyDown={this.onKeyDownInput}
                                        onBlur={this.onKeyDownInput}
                                    />
                                </div>
                                <div className="ladiui-row" style={{ marginTop: "15px" }}>
                                    {_.map(listTags, (tag, key) => {
                                        return (
                                            <a key={key} className="white-list-domain">
                                                {tag}
                                                <span onClick={() => this.removeTag(tag)} >
                                                    <i className="ladiui icon icon-ldp-close" ></i>
                                                </span>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="ladiui-modal-footer">
                        <button className="ladiui button secondary close-modal icon" data-dismiss="modal-upgrade-store"><i className="ladi-icon icon-e-remove" data-dismiss="modal-upgrade-store" onClick={this.props.onCancel} />Close</button>
                        <button
                            ref={this.ref}
                            className={this.state.classApply}
                            onClick={this.submit}
                            disabled={this.state.loading}
                        >
                            <i className="ladi-icon icon-floppy-disk" style={{ display: (this.state.loading) ? 'none' : '', paddingRight: '5px' }}></i> Áp dụng </button>

                        {/* {this.props.loading && <Spinner />} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        upgrade: (store) => dispatch(storeActions.upgrade(store)),
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ActiveLadiChat);