import React from 'react';
import PropTypes from 'prop-types';
import userActions from '../../../redux/futures/user/actions';
import storeActions from '../../../redux/futures/store/actions';
import orderActions from '../../../redux/futures/order/actions';
import * as types from '../../../redux/futures/store/types';
import * as orderTypes from '../../../redux/futures/order/types';
import appConst from '../../../config/const';
import _ from 'lodash';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner';
import baseHelper from '../../../helpers/BaseHelper';
import Form from '../../stores/components/Form';
import ActiveForm from '../../stores/components/ActiveForm';
import ActiveLadiChat from '../../stores/components/ActiveLadiChat';
import ActiveLadiBoost from '../../stores/components/ActiveLadiBoost';
import ChangePackageStore from '../../stores/components/ChangePackageStore';
import DownGrade from '../../stores/components/DownGrade';



class View extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            currentUser: {
                email: "",

                fullname: "",

                last_login: "",

                packages: "", // thông tin thanh toán

                phone: "",

                status: "",

                point: "",

                credit: "",

                created_at: "",

                _id: "",

                web_id: "",

                code: "",

                is_verify_email: false,
                is_verify_domain: false

            },
            appCode: 'LP',
            currentStore: {},
            currentDetail: {},
            stores: [],
            apps: [],
            appType: false,
            blogs: [],
            webs: [],
            selectedApp: {
                name: appConst.STORE_CONFIG.APP_CODE.LP.name,
                value: appConst.STORE_CONFIG.APP_CODE.LP.value,
                selected: appConst.STORE_CONFIG.APP_CODE.LP,
            }
        };

    }


    componentDidMount() {
        window.LadiUI.init();


    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.currentUser && this.state.currentUser !== nextProps.currentUser) {
            this.setState({
                currentUser: nextProps.currentUser,
                stores: [],
                detail: []
            }, () => {
                const { currentUser, selectedApp } = this.state;
                const data = {
                    store_name: '',
                    app_code: selectedApp.value,
                    limit: 20,
                    offset: 0,
                    email: currentUser.email

                };
                if (currentUser.email != '' && currentUser.email != undefined) {
                    this.props.listStore(data);
                }
                const date = new Date();
                const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

                const dataOrder = {
                    "ladi_uid": currentUser._id,
                    "from_date": firstDay,
                    "to_date": new Date(),

                };

                this.props.listDetail(dataOrder);

            });
        }
        if (nextProps.action && !_.includes(this.muteActions, nextProps.action)
            && nextProps.action === types.LIST_STORE) {
            if (nextProps.status) {
                // window.LadiUI.toastr('success', '', nextProps.message);

            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.UPGRADE_STORE) {
            if (nextProps.status) {
                const seft = this;
                setTimeout(function () {
                    const { currentUser, selectedApp } = seft.state;
                    const data = {
                        store_name: '',
                        app_code: selectedApp.value,
                        limit: 20,
                        offset: 0,
                        email: currentUser.email

                    };
                    seft.props.listStore(data);
                    const date = new Date();
                    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                    const dataOrder = {
                        "ladi_uid": currentUser._id,
                        "from_date": firstDay,
                        "to_date": new Date()
                    };

                    seft.props.listDetail(dataOrder);
                }, 1500);

                window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.DOWNGRADE_STORE || nextProps.action == types.CHANGE_PACKAGE_STORE) {
            if (nextProps.status) {
                const seft = this;
                setTimeout(function () {
                    const { currentUser, selectedApp } = seft.state;
                    const date = new Date();
                    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                    const dataOrder = {
                        "ladi_uid": currentUser._id,
                        "from_date": firstDay,
                        "to_date": new Date()
                    };

                    seft.props.listDetail(dataOrder);
                    const data = {
                        store_name: '',
                        app_code: selectedApp.value,
                        limit: 20,
                        offset: 0,
                        email: currentUser.email

                    };
                    seft.props.listStore(data);
                }, 1500);

                // window.LadiUI.toastr('success', '', nextProps.message);
            } else {
                window.LadiUI.toastr('error', '', nextProps.message);
            }
        }

        if (nextProps.action == types.LIST_STORE) {
            const { appCode } = this.state;
            if (nextProps.status) {
                let listBlogs = [];
                let listWebs = [];
                let listApps = [];
                let listStores = nextProps.stores;
                if (appCode == 'LP_APP') {
                    for (let i = 0; i < nextProps.stores.length; i++) {
                        listApps = nextProps.stores;
                        listStores = [];
                    }
                }
                for (let i = 0; i < nextProps.stores.length; i++) {
                    if (nextProps.stores[i].blog) {
                        listBlogs = listBlogs.concat(nextProps.stores[i].blog)
                    }
                    if (nextProps.stores[i].web) {
                        listWebs = listWebs.concat(nextProps.stores[i].web)
                    }
                }
                this.setState({
                    stores: listStores,
                    blogs: listBlogs,
                    webs: listWebs,
                    apps: listApps
                });
            } else {
            }
        }

        if (nextProps.action == orderTypes.LIST_ORDER_DETAIL) {

            if (nextProps.status) {

                this.setState({
                    details: nextProps.details,
                });
            } else {
            }
        }
    }

    activeLadiboost = () => {
        const { currentUser } = this.state;
        this.props.listPackagesLadiboost();
        this.props.infoLadiboost({ ladi_uid: currentUser.ladi_uid || currentUser._id });
    }

    upgradeStore = (currentStore, type = false, appType) => {
        const { selectedApp, currentUser } = this.state;
        // this.formRef.current.resetForm();
        console.log('currentStore=  ', currentStore);
        if (type == 'BLOG') {
            currentStore.name = currentStore.domain || currentStore.name;
            currentStore.ladi_uid = currentUser.ladi_uid || currentUser._id;
            currentStore.blog_id = currentStore._id;
        }
        if (type == 'WEBSITE') {
            currentStore.name = currentStore.domain || currentStore.name;
            currentStore.ladi_uid = currentUser.ladi_uid || currentUser._id;
            currentStore.web_id = currentStore._id;
        }
        this.setState({
            currentStore: null,
            type,
            appType
        }, () => {
            this.setState({
                currentStore,
                appCode: selectedApp.value
            }, () => {
                this.getListPackages();
            })

        });

    }

    changeStorePkg = (currentStore, type = false) => {
        const { selectedApp, currentUser } = this.state;
        // this.formRef.current.resetForm();
        console.log('currentStore=  ', currentStore);

        this.setState({
            currentStore: null,
            type
        }, () => {
            this.setState({
                currentStore,
                appCode: selectedApp.value
            }, () => {
                this.getListPackages();
            })

        });

    }

    cancelOrder = (currentDetail) => {
        let { type } = this.state;
        if (currentDetail.pkg_name.indexOf('BLOG') >= 0) {
            type = 'BLOG';
        } else if (currentDetail.pkg_name.indexOf('WEB') >= 0) {
            type = 'WEBSITE';
        } else {
            type = false;
        }
        this.setState({
            currentDetail,
            selectedApp: {
                name: appConst.STORE_CONFIG.APP_CODE[currentDetail.pkg_type].name,
                value: appConst.STORE_CONFIG.APP_CODE[currentDetail.pkg_type].value,
                selected: appConst.STORE_CONFIG.APP_CODE[currentDetail.pkg_type],
            },
            appCode: currentDetail.pkg_type,
            type
        }, () => {
            this.getListPackages();
        });
    }


    onCancel = () => {
        this.setState({
            formVisible: false
        });
    }

    getListPackages = (app_code = 'LP') => {
        const { currentUser, selectedApp, type, appType } = this.state;
        const data = {
            app_code: selectedApp.value,
            type,
            app_type: appType
        };
        this.props.listPackages(data);
        this.props.getUserinfo({ ladi_uid: currentUser._id });

    }

    openCreateForm = () => {
        const { currentUser, selectedApp } = this.state;
        this.getListPackages(selectedApp.value);
        console.log('openCreateForm', this.state.currentUser);
        this.setState({
            appCode: 'LS',
            currentUser
        })
    }

    openLadiChatForm = () => {
        this.getListPackages('LC');
        console.log('openCreateForm 2', this.state.currentUser);
        const { currentUser } = this.state;
        this.setState({
            appCode: 'LC',
            currentUser
        })
    }

    onChangeApp = (app) => {

        this.setState({
            selectedApp: {
                name: app.name,
                value: app.value,
                selected: app
            },
            appCode: app.value,
        }, () => {
            const { selectedApp, currentUser } = this.state;
            const data = {
                store_name: '',
                app_code: selectedApp.value,
                limit: 20,
                offset: 0,
                email: currentUser.email

            };
            console.log(' selected app = ', selectedApp.value)
            if (currentUser.email != '' && currentUser.email != undefined) {
                this.props.listStore(data);
                if (selectedApp.value == appConst.STORE_CONFIG.APP_CODE.LP_APP.value) return;
                if (selectedApp.value != appConst.STORE_CONFIG.APP_CODE.LF.value) this.getListPackages();
            }
        })
    }

    render() {
        const { totalRecord, totalPage, details } = this.props;
        const { currentUser, selectedApp, stores, blogs, webs, appCode, type, apps, appType } = this.state;
        const packages = (currentUser && currentUser.packages) ? JSON.parse(currentUser.packages) : {};
        const userInfo = baseHelper.getCookie(appConst.ADMIN_INFO);

        return (
            <div id="modal-userview" className="ladiui-modal fade" tabIndex={-1} data-dismiss="modal-userview">
                <div className="ladiui-modal-content" style={{ maxWidth: '1150px' }}>
                    <div className="ladiui-modal-header">
                        <button type="button" className="ladiui-close-modal" data-dismiss="modal-userview"><i className="ladi-icon icon-e-remove" data-dismiss="modal-userview" /></button>
                        <h3>Thông tin khách hàng</h3>
                    </div>
                    <div className="ladiui-modal-body">
                        <ul className="ladiui-tabs">
                            <li className="active">
                                <a data-tab="tab-user-view" >Thông tin user</a>
                            </li>
                            {userInfo.roleName != appConst.ROLE.AFFILATE_CARE &&
                                <React.Fragment>
                                    <li>
                                        <a data-tab="tab-store-list" >Danh sách store</a>
                                    </li>
                                    <li>
                                        <a data-tab="tab-order-list" >Danh sách đơn hàng</a>
                                    </li>
                                </React.Fragment>
                            }
                        </ul>
                        <div className="ladiui-tab-content">

                            <div className="ladiui-tab-item active" id="tab-user-view">
                                <button className="ladiui button primary icon-c-add open-modal" modal="modal-active-ladiboost" onClick={this.activeLadiboost}>Kích hoạt LadiBoost</button>

                                <div className="order-box" style={{ width: '970px', margin: '15px' }}>
                                    <div className="ladiui-row" style={{ height: '50px' }}>

                                        <div className="col-50">
                                            <strong>Ladi UID: </strong>
                                            {
                                                currentUser._id
                                            }
                                        </div>


                                        <div className="col-50">
                                            <strong>Point: </strong>
                                            {
                                                currentUser.point
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{ height: '50px' }}>

                                        <div className="col-50">
                                            <strong>Tên: </strong>
                                            {
                                                currentUser.fullname
                                            }
                                        </div>


                                        <div className="col-50">
                                            <strong>Email: </strong>
                                            {
                                                currentUser.email
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{ height: '50px' }}>

                                        <div className="col-50">
                                            <strong>Số điện thoại: </strong>
                                            {
                                                currentUser.phone
                                            }
                                        </div>


                                        <div className="col-50">
                                            <strong>Affiliate Code: </strong>
                                            {
                                                currentUser.code
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{ height: '50px' }}>

                                        <div className="col-50">
                                            <strong>Xác thực email: </strong>
                                            {
                                                currentUser.is_verify_email ? 'Đã xác thực' : 'Chưa xác thực'
                                            }
                                        </div>


                                        <div className="col-50">
                                            <strong>Xác thực domain: </strong>
                                            {
                                                currentUser.is_verify_domain ? 'Đã xác thực' : 'Chưa xác thực'
                                            }
                                        </div>
                                    </div>
                                    <div className="ladiui-row" style={{ height: '50px' }}>
                                        <div className="col-50">
                                            <strong>Thời gian đăng kí: </strong>{baseHelper.formatStrToDate(currentUser.created_at)}
                                        </div>
                                        <div className="col-50">
                                            <strong>Lần cuối đăng nhâp: </strong>{baseHelper.formatStrToDate(currentUser.last_login)}
                                        </div>
                                    </div>
                                    {this.props.loading && <Spinner />}
                                </div>
                            </div>
                            <div className="ladiui-tab-item" id="tab-store-list">
                                <div className="ladiui actions">
                                    <div className="action-item">
                                        <div className="ladiui-select-options">
                                            <span className="ladiui-select-init">{selectedApp.name}</span>
                                            <input type="hidden" name="select" defaultValue />
                                            <ul>
                                                {
                                                    _.map(appConst.STORE_CONFIG.APP_CODE, option =>
                                                        <li key={option.value} className="select-item" onClick={() => this.onChangeApp(option)}><a href="#" data-value={option.value}>{option.name}</a></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ladiui actions">
                                    <div className="action-item">
                                        {this.state.appCode == 'LS' && stores.length == 0 &&
                                            <button style={{ marginRight: "5px" }} className="ladiui button primary icon-c-add open-modal" modal="modal-active-store" onClick={this.openCreateForm}>Kích hoạt LadiSales</button>
                                        }
                                        {this.state.appCode == 'LC' && stores.length == 0 &&
                                            <button className="ladiui button primary icon-c-add open-modal" modal="modal-active-store" onClick={this.upgradeStore}>Kích hoạt LadiChat</button>
                                        }
                                        {this.state.appCode == 'LSHARE' && stores.length == 0 &&
                                            <button className="ladiui button primary icon-c-add open-modal" modal="modal-active-store" onClick={this.upgradeStore}>Kích hoạt LadiShare</button>
                                        }
                                        {this.state.appCode == 'LLINK' && stores.length == 0 &&
                                            <button className="ladiui button primary icon-c-add open-modal" modal="modal-active-store" onClick={this.upgradeStore}>Kích hoạt LadiLink</button>
                                        }
                                    </div>

                                </div>
                                <table className="ladiui table">
                                    <thead>
                                        <tr>

                                            {/* <th className="text-left">ID</th> */}
                                            <th className="text-left">Tên</th>
                                            <th className="text-left">Email</th>
                                            <th className="text-left">Package Name</th>
                                            <th className="text-left">Ngày kích hoạt</th>
                                            <th className="text-left">Ngày hết hạn</th>
                                            <th name="created_at" onClick={this.sort} className="text-left sorting">Thời gian tạo</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(stores, store => {
                                                let pkgActive = store.pkg_actived_at || store.pkg_active_at || store.updated_at;
                                                let displayName = store.pkg_name;
                                                if (store.pkg_name == 'PREMIUM_5') {
                                                    displayName = 'BUSSINESS_3';
                                                }
                                                if (store.pkg_name == 'PREMIUM_10') {
                                                    displayName = 'BUSSINESS_10';
                                                }
                                                if (store.pkg_name == 'PREMIUM_20') {
                                                    displayName = 'BUSSINESS_20';
                                                }
                                                displayName = displayName.replace('PREMIUM', 'BUSSINESS');
                                                return <tr

                                                    key={store.store_id || store._id}
                                                >
                                                    <td>{store.name}</td>
                                                    <td>{store.email}</td>
                                                    <td>{displayName}</td>
                                                    <td>{baseHelper.formatStrToDate(pkgActive)}</td>
                                                    <td>{baseHelper.formatStrToDate(store.pkg_exp)}</td>
                                                    <td>{baseHelper.formatStrToDate(store.created_at)}</td>

                                                    <td>

                                                        {
                                                            userInfo.roleName == appConst.ROLE.ADMIN &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.upgradeStore(store)} modal="modal-upgrade-store" >Nâng cấp / Gia hạn</button>
                                                        }
                                                        {/* {
                                                            userInfo.roleName == appConst.ROLE.ADMIN && this.state.appCode == 'LLINK' &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.changeStorePkg(store)} modal="modal-change-store-package" >Đổi package Store</button>
                                                        } */}

                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                        {


                                            _.map(blogs, blog => {
                                                return <tr

                                                    key={blog._id}
                                                >
                                                    <td></td>
                                                    <td><p style={{ maxWidth: "250px", textOverflow: "ellipsis", overflow: 'hidden' }}>{blog.name || blog.domain}</p></td>
                                                    <td>{blog.pkg_name}</td>
                                                    <td>{blog.pkg_actived_at ? baseHelper.formatStrToDate(blog.pkg_actived_at) : baseHelper.formatStrToDate(blog.updated_at)}</td>
                                                    <td>{baseHelper.formatStrToDate(blog.pkg_exp)}</td>
                                                    <td>{baseHelper.formatStrToDate(blog.created_at)}</td>

                                                    <td>
                                                        {
                                                            userInfo.roleName == appConst.ROLE.ADMIN &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.upgradeStore(blog, 'BLOG')} modal="modal-upgrade-store" >Nâng cấp / Gia hạn BLOG</button>
                                                        }

                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                        {
                                            _.map(webs, web => {
                                                return <tr

                                                    key={web._id}
                                                >
                                                    <td>{web.name}</td>
                                                    <td><p style={{ maxWidth: "250px", textOverflow: "ellipsis", overflow: 'hidden' }}>{web.domain || web.domain_default}</p></td>
                                                    <td>{web.pkg_name}</td>
                                                    <td>{web.pkg_actived_at ? baseHelper.formatStrToDate(web.pkg_actived_at) : baseHelper.formatStrToDate(web.updated_at)}</td>
                                                    <td>{baseHelper.formatStrToDate(web.pkg_exp)}</td>
                                                    <td>{baseHelper.formatStrToDate(web.created_at)}</td>

                                                    <td>
                                                        {
                                                            userInfo.roleName == appConst.ROLE.ADMIN &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.upgradeStore(web, 'WEBSITE')} modal="modal-upgrade-store" >Nâng cấp / Gia hạn WEBSITE</button>
                                                        }

                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                        {
                                            _.map(apps, lpApp => {
                                                return <tr

                                                    key={lpApp._id}
                                                >
                                                    <td>{lpApp.code}</td>
                                                    <td><p style={{ maxWidth: "250px", textOverflow: "ellipsis", overflow: 'hidden' }}>{lpApp.email}</p></td>
                                                    <td>{lpApp.pkg_name || '--'}</td>
                                                    <td>{lpApp.pkg_actived_at ? baseHelper.formatStrToDate(lpApp.pkg_actived_at) : baseHelper.formatStrToDate(lpApp.updated_at)}</td>
                                                    <td>{baseHelper.formatStrToDate(lpApp.pkg_exp)}</td>
                                                    <td>{baseHelper.formatStrToDate(lpApp.created_at)}</td>

                                                    <td>
                                                        {
                                                            userInfo.roleName == appConst.ROLE.ADMIN && lpApp.code == appConst.STORE_CONFIG.LP_APP_CODE.FunnelX &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.upgradeStore(lpApp, 'LP_APP', lpApp.code)} modal="modal-upgrade-store" >Nâng cấp / Gia hạn APP</button>
                                                        }

                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                        {
                                            totalRecord == 0 &&
                                            <tr className="text-center">
                                                <td colSpan="100%">Không có bản ghi nào</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="ladiui-tab-item" id="tab-order-list">
                                <table className="ladiui table">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Store ID</th>
                                            {/* <th className="text-left">Store Name</th> */}
                                            <th className="text-left">App</th>
                                            <th className="text-left">Tên gói</th>
                                            <th className="text-left">Tháng</th>

                                            <th className="text-left">Tháng KM</th>

                                            <th className="text-left">Tổng tiền</th>
                                            <th className="text-left">Credit</th>
                                            <th className="text-right">Thời gian tạo</th>
                                            {/* <th className="text-center">Discount Type</th>
                                        <th className="text-center">Discount Value</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(details, detail => {
                                                return <tr key={detail._id}>
                                                    <td>{detail.store_id}</td>
                                                    {/* <td>{detail.store_name}</td> */}
                                                    <td>{appConst.APP[detail.pkg_type]}</td>
                                                    <td>{detail.pkg_name}</td>
                                                    <td>{detail.pkg_month}</td>
                                                    <td>{detail.addition_month}</td>
                                                    <td>{detail.subtotal.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</td>
                                                    <td>{baseHelper.numberWithCommas(detail.credit_used)}</td>
                                                    <td className="text-right">{baseHelper.formatStrToDate(detail.created_at)}</td>
                                                    <td>

                                                        {
                                                            userInfo.roleName == appConst.ROLE.ADMIN && detail.credit_used == 0 &&
                                                            <button className="ladiui button primary open-modal" onClick={() => this.cancelOrder(detail)} modal="modal-downgrade-store" >Huỷ Order</button>
                                                        }


                                                    </td>
                                                </tr>
                                            }
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                </div>
                <ActiveForm
                    ref={this.formRef}
                    onCancel={this.onCancel}
                    currentUser={currentUser}
                    appCode={this.state.appCode}
                />
                <ActiveLadiBoost
                    ref={this.formRef}
                    currentUser={currentUser}
                    onCancel={this.onCancel}
                />
                <ActiveLadiChat
                    ref={this.formRef}
                    currentStore={this.state.currentStore}
                    appCode={this.state.appCode}
                    onCancel={this.onCancel}
                    typeUpgrade={type}
                    appType={appType}
                />

                <ChangePackageStore
                    ref={this.formRef}
                    currentStore={this.state.currentStore}
                    appCode={this.state.appCode}
                    onCancel={this.onCancel}
                    typeUpgrade={type}
                />

                <DownGrade
                    ref={this.formRef}
                    currentDetail={this.state.currentDetail}
                    currentUser={currentUser}
                    appCode={this.state.appCode}
                    onCancel={this.onCancel}
                    typeUpgrade={type}
                />
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (affilateInfo) => dispatch(userActions.updateUserInfo(affilateInfo)),
        getAffilateInfo: () => dispatch(userActions.myAffilateInfo()),
        listStore: (data) => dispatch(storeActions.list(data)),
        listDetail: (data) => dispatch(orderActions.listDetail(data)),
        listPackages: (data) => dispatch(storeActions.listPackages(data)),
        listPackagesLadiboost: () => dispatch(storeActions.listPackagesLadiboost()),
        infoLadiboost: (data) => dispatch(storeActions.infoLadiboost(data)),
        getUserinfo: (data) => dispatch(storeActions.getUserinfo(data))
    }
}

const mapStateToProps = (state) => ({ ...state.store });

export default connect(mapStateToProps, mapDispatchToProps)(View);